import CartClientV2, { removeCartItemImportant } from 'clients/CartClientV2';
import { getFirst, isValid, isValidWithoutData, PUT } from 'clients/Clients';
import ProductClientV2 from 'clients/ProductClientV2';
import { CART_API } from 'constants/APIUriV2';
import { CART_URL, CHECKOUT_URL } from 'constants/Paths';
import { SHARED_SCREEN_NAME } from 'context/Cart/CartContext';
import Router from 'next/router';
import { convertCartStore } from 'services/ProductServiceV3';
import type { Product, Seller } from 'types/product';
import { CartStore, IOldCartItem, IRedeemApplyResult } from 'types/product';
import gtag from 'utils/gtag';
import Insider, { INSIDER_CART_SOURCE } from 'utils/Insider';
import { mapScreenToEnum, mapSourceToEnum } from 'utils/MonitorUtils';
import NotifyUtils from 'utils/NotifyUtils';
import { capitalizeText } from 'utils/StringUtils';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  CartParams,
  FETCH_STATUS,
  FetchStatus,
  IMessages,
  ImportantProductPayload,
  Options,
  ProductUpdate,
  RemoveCartItemsSelectedPayload,
  RemoveCartPayload,
  SelectAllPayload,
  SelectProductPayload,
  SelectStorePayload,
  UpdateCartPayload,
} from './InterfaceZustand';
import WebServiceClient from "../clients/WebServiceClient";
import { APIStatus } from "@thuocsi/customer-live-chat/src/utils/common";

// Actions are functions which update values in your store. These are static and never change, so they aren't technically "state".
// Organising them into a separate object in our store will allow us to expose them as a single hook to be used in any our component
// without any impact on performance:
interface Actions {
  fetchCartData: () => Promise<void>;
  fetchCartDataByRouter: () => Promise<void>;
  setSelectAll: (payload: SelectAllPayload, options?: Options) => Promise<void>;
  setSelectStore: (payload: SelectStorePayload, options?: Options) => Promise<void>;
  setSelectProduct: (payload: SelectProductPayload, options?: Options) => Promise<void>;
  setImportantProduct: (payload: ImportantProductPayload, options?: Options) => Promise<void>;
  // TODO: Function updateCartItem use for increase or decrease quantity of cart
  updateCartItem: (payload: UpdateCartPayload, options?: Options) => Promise<void>;
  removeCartItemsSelected: (payload: RemoveCartItemsSelectedPayload) => Promise<void>;
  removeCartItem: (payload: RemoveCartPayload, options?: Options) => Promise<void>;
  removeImportantProducts: (payload: { cartNo: string; skus: string[] }, options?: Options) => Promise<void>;
  setSelectedLists: (payload: any) => void;
  setFirstTimeGetCart: (status: boolean) => void;
  resetFirstTimeGetCart: () => void;
  updateRedeemApplyResult: (payload: any) => void;
  updatePromosRemoved: (payload: string) => void;
  resetPromosRemoved: () => void;
  updateNextUsingPromo: (
    payload: {
      code: string;
      paymentMethod: string;
      paymentMethodName: string;
    } | null,
  ) => void;

  getIDRef: (product: Partial<Product & ProductUpdate>) => string;
  getSKUfromIDRef: (idRef: string) => string;
  updateTotalQuantity: (totalQuantity: number) => void;
  // old cart context
  togglePromoList: (payload: boolean) => void;
  multiVoucherHandleChangePromoV2: (payload: string[]) => Promise<{
    data: any;
    message: string;
    status: string;
    errorCode?: string;
  }>;
  multiVoucherHandleRemovePromoV2: (payload: string[]) => Promise<{
    data: any;
    message: string;
    status: string;
    errorCode?: string;
  }>;
  setDeletingPromo: (payload: boolean) => void;
  toggleShowRemoveInvalidPromos: (payload: boolean) => void;
  reloadDataCart: (payload: any, options?: Options) => void;
  toggleOnCheckingPromo: (payload: boolean) => void;
  clearCart: () => void;
  toggleShowModalMax200Items: () => void;
  mapDataProduct: (payload: any) => void;
  handleScrollToGiftRow: () => void;
  updatePaymentMethod: (
    payload: {
      paymentMethod: string;
      customerDistrictCode?: string;
      customerProvinceCode?: string;
      customerWardCode?: string;
      info: any;
    },
    options?: Options,
  ) => Promise<void>;
  unSelectAllCartItem: () => Promise<void>;
  removeImportant: (payload: any, options?: Options) => Promise<void>;
  updateDeliveryMethod: (payload: {
    deliveryMethod: string;
    customerDistrictCode?: string;
    customerProvinceCode?: string;
    customerWardCode?: string;
    customerShippingAddress?: string;
    info: any;
  }) => Promise<void>;
  updateCart: (payload: any, options?: Options, message?: IMessages) => Promise<void>;
  updateRefuseSplitOrder: (payload: boolean) => Promise<void>;
  updateDeliveryInfo: (payload: any) => Promise<void>;
  verifyPayment: (payload: any, options?: Options) => void;
  updateInvoice: (payload: any, message?: IMessages) => Promise<void>;
  updateCartInfo: (payload: any, message?: IMessages) => Promise<void>;
}

interface Refs {
  cartRefs: {
    giftProductRowRef: any;
    availablePromoSectionRef: any;
    totalSaveSectionRef: any;
  };
  setCartRefs: (name: keyof Refs['cartRefs'], ref: any) => void;
}

type InitialCartState = CartStore & { state: FetchStatus };
type CartState = InitialCartState & { actions: Actions; refs: Refs };

const INITIAL_STATE: InitialCartState = {
  cartNo: '',
  cartData: [],
  cartDiscounts: [],
  cartFluctuates: [],
  cartErrors: [],
  selectedProducts: [],
  isOpenImportantProducts: false,
  isSelected: false,
  totalDiscount: 0,
  totalItems: 0,
  totalPrice: 0,
  totalItemsSelected: 0,
  totalQuantitySelected: 0,
  totalQuantity: 0,
  totalItemsImportantSelected: 0,
  price: 0,
  state: FETCH_STATUS.IDLE,
  hasDeal: false,
  selectedLists: {},
  isFirstTimeGetCart: true,
  redeemApplyResult: [],
  paymentMethod: '',
  redeemCode: [],
  oldCartItems: [],
  subPrice: 0,
  promosRemoved: '',
  nextUsingPromo: null,
  lastUpdatedIDRef: '',
  // old cart context
  isDeletingPromo: false,
  isShowRemoveInvalidPromos: false,
  isOnCheckingPromo: false,
  isShowModalMax200Item: false,
  note: '',
  isSelectedAll: false,
  isErrorImportant: false,
  errProducts: [],
  deliveryMethod: '',
  deliveryFeeConfigs: [],
  customerName: '',
  customerPhone: '',
  customerProvinceCode: '',
  customerRegionCode: '',
  customerDistrictCode: '',
  customerWardCode: '',
  customerShippingAddress: '',
  customerAddressCode: '',
  customerProvinceName: '',
  customerWardName: '',
  customerDistrictName: '',
  isAllowRefuseSplitOrder: false,
  isRefuseSplitOrder: false,
  bankPaymentInformation: {},
  vatItems: {},
  nonVatItems: {},
  paymentFeeConfigs: [],
  cartItems: [],
  transactionDetail: {},
  extraFee: 0,
  loading: true,
  reloadingData: false,
};

const INITIAL_OPTIONS = {
  isReload: true,
  isNotify: true,
};

const listAllowGetPaymentMethod = [CHECKOUT_URL];

const useCartStates = create<CartState>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    // ⬇️ separate "namespace" for actions
    actions: {
	  fetchCartDataByRouter: async () => {
		  const path = window.location.pathname
		  const {
			  actions: { fetchCartData },
		  } = get();

		  // Cause in cart route, we need update all info relative to cart like: update voucher used, total price,... after we change quantity
		  // => Need to call fetchCartData
		  if(path === '/cart') {
			  await fetchCartData()
			  return;
		  }

		  // We only care about QUANTITY => no need call fetCartData to update all info relative to cart
		  // => call cartLiteEncrypted is enough
		  const res = await WebServiceClient.loadDataCartLiteEncrypted();
		  if(res.status === APIStatus.OK) {
			  set((_) => {
				  const getFirstDataCart = getFirst(res);
				  const cartStore: CartStore = convertCartStore(getFirstDataCart);

				  const products = getFirstDataCart.cartItems.map((item: any) => ({
					  ...item,
					  skuCode: item.sku,
					  quantityInCart: item.quantity,
				  }))

				  const cartStoreDto  = {
					  ...cartStore,
					  cartData: [{ products }] as unknown as Seller[],
				  }
				  return ({
					  ...cartStoreDto,
					  state: FETCH_STATUS.IDLE,
					  isFirstTimeGetCart: false,
					  totalQuantity: getFirst(res).totalQuantity,
				  })
			  });
			  return;
		  }
		  set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));

	  },
	   // this method only call when we need update all info relative to cart info
	   // avoid call it when only need renew quantity
      fetchCartData: async () => {
        try {
          const { isFirstTimeGetCart, promosRemoved } = get();
          const params: {
            isFirstTimeGetCart: boolean;
            promosRemoved: string;
            getPaymentMethod: boolean;
            screen: string;
          } = {
            isFirstTimeGetCart: isFirstTimeGetCart ?? false,
            promosRemoved: promosRemoved || '',
            getPaymentMethod: listAllowGetPaymentMethod.includes(Router?.pathname),
            screen: '',
          };
          if (Router?.pathname === CHECKOUT_URL) {
            params.screen = SHARED_SCREEN_NAME.PAYMENT;
          }

          // TODO: firstTimeGetCart is using for get auto voucher for user, only apply on cart page
          if (Router?.pathname !== CART_URL) {
            params.isFirstTimeGetCart = false;
          }

          set((state) => ({ ...state, reloadingData: true }));
          const res = await ProductClientV2.getCartBySeller(params);
          const getFirstDataCart = getFirst(res);

          if (!getFirstDataCart) {
            set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));
            return;
          }
          const cartStore: CartStore = convertCartStore(getFirstDataCart);
          const oldCartItemsFormat = cartStore.cartData.map((item) =>
            item.products
              ?.filter((child) => child.isSelected)
              ?.map((product) => ({
                quantity: product.quantityInCart,
                price: product.noneVoucherPrice,
                total: Number(product.noneVoucherPrice) * Number(product.quantityInCart),
                sku: product.skuCode,
                sellerCode: product.sellerCode,
                productTags: product.productTags || [],
                productCode: product.productCode,
                isSelected: product.isSelected,
                storeCode: product.storeCode || '',
                errorCode: product.errorCode || '',
                errorMessage: product.errorMessage || '',
                deal: product?.deal,
                isDeal: product?.isDeal || false,
                defaultImage: product?.imageUrl || '',
                name: product?.name || '',
                slug: product?.slug || '',
              })),
          );

          const newData = {
            ...cartStore,
            state: FETCH_STATUS.IDLE,
            isFirstTimeGetCart: false,
            oldCartItems: oldCartItemsFormat.flat() as IOldCartItem[],
            redeemCode: cartStore?.redeemApplyResult?.map((item: IRedeemApplyResult) => item.code),
            reloadingData: false,
            errProducts: oldCartItemsFormat?.flat()?.filter((item) => item?.isSelected && (item?.errorCode || item?.errorMessage)) || [],
          };
          set(() => newData);

          Insider.updateInsiderCartInfo(newData.cartData, INSIDER_CART_SOURCE.CART_STATE);
        } catch (error) {
          set(() => ({
            ...INITIAL_STATE,
            state: FETCH_STATUS.ERROR,
            reloadingData: false,
          }));
        }
      },
      setSelectAll: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { isReload } = options;
          const { isSelected } = payload;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.selectCartItemV2({
            isSelected,
            isAppliedAll: true,
            cartNo,
          });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Không thể chọn nhà bán hàng này, vui lòng thử lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectStore: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { isSelected, skus, sellerGroup } = payload;
          const { isReload } = INITIAL_OPTIONS;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          await CartClientV2.selectCartItemV2({ isSelected, skus, cartNo });

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setSelectProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
          } = get();
          const { name, skuCode, isSelected, quantityInCart, sellerGroup } = payload;
          const { isReload } = options;

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.selectCartItemV2({
            isSelected,
            sku: skuCode,
            cartNo,
            quantity: quantityInCart || 0,
            name: name || '',
          });

          if (!isValid(res)) {
            NotifyUtils.error('Chọn sản phẩm không thành công, vui lòng chọn lại');
          }

          if (isReload) {
            await fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      setImportantProduct: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            actions: { fetchCartData },
          } = get();
          const { cartNo, sku, type, isImportant } = payload;
          const { isReload } = options;

          const res = await CartClientV2.updateCartItemImportant({
            cartNo,
            sku,
            type,
            isImportant,
            eventSource: mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
            host: window.location.host || '',
            source: 'thuocsi-web',
          } as CartParams & ImportantProductPayload);

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Đánh dấu quan trọng sản phẩm thất bại');
            return;
          }

          if (isReload) {
            await fetchCartData();
          }
          NotifyUtils.success('Đánh dấu quan trọng thành công');
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateCartItem: async (payload, options) => {
        try {
          const {
            cartNo,
            actions: { fetchCartDataByRouter },
          } = get();
          const { idRef, newQuantity, product } = payload;
          const { isNotify, isReload } = options || INITIAL_OPTIONS;

          const res = await CartClientV2.addCartItem({
            ...product,
            quantity: newQuantity,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource: product.eventSource || mapSourceToEnum({}, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({}, window.location.pathname) || '',
	        blockCode: product?.blockCode,
	        host: window.location.host || '',
          } as CartParams & ProductUpdate);

          if (!isValid(res)) {
            NotifyUtils.error('Cập nhật số lượng không thành công');
            return;
          }

          gtag.addToCart({ ...product, quantity: newQuantity || 0 });
          gtag.addToCartInPage({ ...product, quantity: newQuantity || 0 }, window.location.pathname);

          set((state) => ({
            ...state,
            lastUpdatedIDRef: idRef,
            isFirstTimeGetCart: true,
          }));
		  //
          if (isReload) {
             await fetchCartDataByRouter();
          }
		  //
          if (isNotify) {
            const productName = capitalizeText(product.name);
            const message =
              (product?.quantity ?? 0) > newQuantity
                ? `Số lượng sản phẩm ${productName} đã được giảm.`
                : `Số lượng sản phẩm ${productName} đã được tăng.`;

            NotifyUtils.success(message);
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItemsSelected: async (payload) => {
        try {
          const {
			cartNo,
            actions: { fetchCartDataByRouter },
          } = get();

	      const { skus } = payload;

		  set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

	      await CartClientV2.removeCartItem({
		        cartNo,
		        skus,
		        source: 'thuocsi-web',
	      });

          await fetchCartDataByRouter();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeCartItem: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const {
            cartNo,
            actions: { fetchCartDataByRouter },
          } = get();
          const { idRef, product } = payload;
          const { isNotify } = options;

          set((state) => ({
            ...state,
            lastUpdatedIDRef: idRef,
            isFirstTimeGetCart: true,
          }));

          const res = await CartClientV2.removeCartItem({
            ...product,
            cartNo,
            page: window.location.pathname || 'home',
            searchKey: window.location.search,
            eventSource: product?.eventSource || mapSourceToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            eventScreen: mapScreenToEnum({ isRecommended: false, isSameCategoryProduct: false }, window.location.pathname) || '',
            host: window.location.host || '',
	        blockCode: product?.blockCode,
          } as CartParams & ProductUpdate);

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Xoá sản phẩm thất bại');
            return;
          }

          if (isNotify) {
            NotifyUtils.success(`Sản phẩm ${capitalizeText(product.name)} đã được xóa ra khỏi giỏ hàng`);
          }

          await fetchCartDataByRouter();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeImportantProducts: async (payload, options = INITIAL_OPTIONS) => {
        const {
          actions: { fetchCartDataByRouter },
        } = get();

        const res = await removeCartItemImportant({
          cartNo: payload.cartNo,
          skus: payload.skus,
        });

        const { isReload } = options;
        if (isReload) await fetchCartDataByRouter();
      },
      setSelectedLists: async (data) => {
        set(() => ({
          selectedLists: data,
        }));
      },
      setFirstTimeGetCart: (status: boolean) => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: status,
        }));
      },
      resetFirstTimeGetCart: () => {
        set((state) => ({
          ...state,
          isFirstTimeGetCart: true,
        }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateRedeemApplyResult: (newRedeemApplyResult: any) => {
        set((state) => ({
          ...state,
          redeemApplyResult: newRedeemApplyResult,
        }));
      },
      updatePromosRemoved: (promosRemoved: string) => {
        set((state) => ({
          ...state,
          promosRemoved,
        }));
      },
      resetPromosRemoved: () => {
        set((state) => ({
          ...state,
          promosRemoved: '',
        }));
      },
      updateNextUsingPromo: (nextUsingPromo) => {
        set((state) => ({
          ...state,
          nextUsingPromo,
        }));
      },

      getIDRef: (product: Partial<Product & ProductUpdate>) => {
        return `${Math.random()}::${product.skuCode || product.sku}`;
      },
      getSKUfromIDRef: (idRef: string) => {
        return (idRef || '').split('::')[1];
      },
      updateTotalQuantity: (totalQuantity: number) => {
        set((state) => ({
          ...state,
          totalQuantity,
        }));
      },
      togglePromoList: (payload) => {
        set((state) => ({
          ...state,
          isOpenPromoList: payload,
        }));
      },
      multiVoucherHandleChangePromoV2: async (payload) => {
        const { cartNo } = get();
        const res = await CartClientV2.putChangeMultiRedeem(payload, cartNo);
        return res;
      },
      multiVoucherHandleRemovePromoV2: async (payload) => {
        const { cartNo } = get();
        const res = await CartClientV2.putRemoveMultiRedeem(payload, cartNo);
        return res;
      },
      setDeletingPromo: (payload) => {
        set((state) => ({
          ...state,
          isDeletingPromo: payload,
        }));
      },
      toggleShowRemoveInvalidPromos: (payload) => {
        set((state) => ({
          ...state,
          isShowRemoveInvalidPromos: payload,
        }));
      },
      reloadDataCart: (payload, options = INITIAL_OPTIONS) => {
        const { cartRes, errorMessage, successMessage } = payload;
        try {
          if (cartRes && !isValidWithoutData(cartRes)) {
            const cartResponse = getFirst(cartRes);
            if (!cartResponse) {
              set(() => ({ ...INITIAL_STATE, state: FETCH_STATUS.ERROR }));
              return;
            }
            switch (cartResponse.errorCode) {
              case 'MAX_QUANTITY':
                if (cartResponse?.quantity > 0) {
                  NotifyUtils.error(`${cartRes.message}, Số lượng hàng còn lại là ${cartResponse.quantity}`);
                } else {
                  NotifyUtils.error(`${cartRes.message}`);
                }
                break;
              case 'PAYLOAD_VALIDATE':
                if (cartRes.message === 'Type must be one of [NORMAL DEAL COMBO CAMPAIGN]') {
                  NotifyUtils.error(`Không thể thêm sản phẩm là Quà tặng vào giỏ hàng`);
                } else {
                  NotifyUtils.error('Đã có lỗi xảy ra, vui lòng thử lại');
                }
                break;
              case 'INVALID_SESSION_TOKEN':
                NotifyUtils.error(`Tài khoản đã bị đăng xuất, xin vui lòng đăng nhập lại.`);
                break;
              default:
                if (cartRes && cartRes.message) {
                  NotifyUtils.error(`${cartRes.message}`);
                } else if (errorMessage) {
                  NotifyUtils.error(errorMessage);
                }
                return;
            }
          }

          if (successMessage) NotifyUtils.success(successMessage);
          const { fetchCartData } = get().actions;
          const { isReload } = options;
          if (isReload) {
            fetchCartData();
          }
        } catch (err) {
          NotifyUtils.error(errorMessage);
        }
      },
      toggleOnCheckingPromo: (payload) => {
        set((state) => ({
          ...state,
          isOnCheckingPromo: payload,
        }));
      },
      clearCart: () => {
        set(() => ({ ...INITIAL_STATE }));
      },
      toggleShowModalMax200Items: () => {
        set((state) => ({
          ...state,
          isShowModalMax200Item: !state.isShowModalMax200Item,
        }));
      },
      mapDataProduct: (payload) => {
        //
      },
      handleScrollToGiftRow: () => {
        const { cartRefs } = get().refs;
        if (cartRefs.giftProductRowRef && cartRefs.giftProductRowRef.current) {
          cartRefs.giftProductRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }
      },
      updatePaymentMethod: async (payload) => {
        const { paymentMethod, customerDistrictCode, customerProvinceCode, customerWardCode, info } = payload;
        const { cartNo } = get();
        const res = await CartClientV2.updatePaymentMethod({
          cartNo,
          paymentMethod,
          customerDistrictCode,
          customerProvinceCode,
          customerWardCode,
          ...info, // forloag
        });

        if (!isValid(res)) {
          NotifyUtils.error(res.message || 'Cập nhật phương thức thanh toán thất bại ');
          return;
        }
        NotifyUtils.success('Cập nhật phương thức thanh toán thành công');
        const { fetchCartData } = get().actions;
        fetchCartData();
      },
      unSelectAllCartItem: async () => {
        try {
          const {
            cartNo,
            actions: { fetchCartData },
            cartData,
          } = get();

          set((state) => ({
            ...state,
            isFirstTimeGetCart: true,
          }));

          const body = { isSelected: false, isAppliedAll: true, cartNo };

          const res = await PUT({ url: CART_API.SELECT_ITEM, body });

          if (!isValidWithoutData(res)) {
            NotifyUtils.error('Không thể chọn nhà bán hàng này, vui lòng thử lại');
          }
          await fetchCartData();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      removeImportant: async (payload, options = INITIAL_OPTIONS) => {
        try {
          const { sku, type, pathname } = payload;
          await CartClientV2.updateCartItemImportant({
            eventSource: mapSourceToEnum({}, pathname) || '',
            eventScreen: mapScreenToEnum({}, pathname) || '',
            sku: sku,
            isImportant: false,
            type: type,
            host: window?.location?.host || '',
          });
          const { isReload } = options;
          if (isReload) {
            const { fetchCartData } = get().actions;
            fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateDeliveryMethod: async (payload) => {
        try {
          const {
            deliveryMethod,
            customerDistrictCode,
            customerProvinceCode,
            customerWardCode,
            customerShippingAddress,
            info, // logs info
          } = payload;
          const { cartNo } = get();

          const resp = await CartClientV2.updateDeliveryMethod({
            deliveryMethod,
            customerDistrictCode,
            customerProvinceCode,
            customerWardCode,
            customerShippingAddress,
            ...info,
            cartNo,
          });

          if (!isValid(resp)) {
            NotifyUtils.error(resp?.message || 'Cập nhật phương thức giao hàng thất bại ');
            return;
          }

          NotifyUtils.success('Cập nhật phương thức giao hàng thành công');
          const { fetchCartData } = get().actions;
          fetchCartData();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateCart: async (payload, options = INITIAL_OPTIONS, message) => {
        try {
          const resp = await CartClientV2.updateCart(payload);
          if (!isValid(resp)) {
            NotifyUtils.error(message?.error || resp?.message);
            return;
          }
          if (options?.isReload) {
            const { fetchCartData } = get().actions;
            fetchCartData();
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateRefuseSplitOrder: async (payload) => {
        try {
          const resp = await CartClientV2.updateRefuseSplitOrder(payload);
          if (!isValid(resp)) {
            NotifyUtils.error(resp?.message || 'Cập nhật lại hình thức nhận hàng thất bại ');
            return;
          }
          NotifyUtils.success('Cập nhật lại hình thức nhận hàng thành công ');
          const { fetchCartData } = get().actions;
          fetchCartData();
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateDeliveryInfo: async (payload) => {
        try {
          const resp = await CartClientV2.updateDeliveryMethod({
            ...payload,
          });
          if (!isValid(resp)) {
            return;
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      verifyPayment: async (payload) => {
        try {
          const reps = await CartClientV2.verifyPayment({ ctx: '', body: payload });
          return reps;
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateInvoice: async (payload, message) => {
        try {
          const resp = await CartClientV2.updateCart({body: payload, ctx: ''});
          if (!isValid(resp)) {
            NotifyUtils.error(message?.error || resp?.message);
            return;
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      },
      updateCartInfo: async (payload, message) => {
        try {
          const resp = await CartClientV2.updateCart({body: payload, ctx: ''});
          if (!isValid(resp)) {
            NotifyUtils.error(message?.error || resp?.message);
            return;
          }
        } catch (error) {
          set((state) => ({
            ...state,
            state: FETCH_STATUS.ERROR,
          }));
        }
      }
    },
    refs: {
      cartRefs: {
        giftProductRowRef: null,
        availablePromoSectionRef: null,
        totalSaveSectionRef: null,
      },
      setCartRefs: (name, ref) =>
        set((state) => ({
          refs: {
            ...state.refs,
            cartRefs: {
              ...state.refs.cartRefs,
              [name]: ref,
            },
          },
        })),
    },
  })),
);

export const useCartActions = () => useCartStates((state) => state.actions);
export const useCartRefs = () => useCartStates((state) => state.refs);
export const useCartSetCart = useCartStates.setState;
export const useCartGetCart = useCartStates.getState;

export default useCartStates;
