export const settingsCustomerSeller = {
  slidesToShow: 3,
  slidesToScroll: 1,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1198,
      settings: {
        slidesToShow: 2,
        rows: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        rows: 1,
      },
    },
  ],
};

export const settingsProduct = {
  className: 'section-outstanding__slider',
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  infinite: false,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1140,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

export const settingsProductMV2 = {
  className: 'section-outstanding__slider',
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  autoplaySpeed: 3000,
  speed: 500,
};

export const settingsSliderBanner = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
  arrows: false,
  infinite: true,
  rows: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  swipeToSlide: true,
};

export const settingsSliderBannerV2 = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  infinite: true,
  rows: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  swipeToSlide: true,
};

// export const swiperSettings = {
//   modules: [Pagination, Navigation],
//   pagination: {
//     clickable: true,
//   },
// };

export const SORT_PRODUCT_NOT_LOGIN = [
  { label: 'Bán chạy nhất', value: '' },
  { label: 'Mới nhất', value: 'NEWEST' },
  { label: 'Giá: Cao đến Thấp', value: 'PRICE_DESC' },
  { label: 'Giá: Thấp đến Cao', value: 'PRICE_ASC' },
  { label: 'Tên: A-Z', value: 'NAME_ASC' },
  { label: 'Tên: Z-A', value: 'NAME_DESC' },
];

export const SORT_PRODUCT = [
  { label: 'Bán chạy nhất', value: '' },
  { label: 'Mới nhất', value: 'NEWEST' },
  { label: 'Giá: Cao đến Thấp', value: 'PRICE_DESC' },
  { label: 'Giá: Thấp đến Cao', value: 'PRICE_ASC' },
  { label: 'Tên: A-Z', value: 'NAME_ASC' },
  { label: 'Tên: Z-A', value: 'NAME_DESC' },
];

export const tabsProductData = [
  { id: 1, label: 'Thông tin chung', value: '1', code: 'description' },
  { id: 2, label: 'Chỉ định', value: '2', code: 'indication' },
  { id: 3, label: 'Liều lượng - Cách dùng', value: '3', code: 'dosage' },
  { id: 4, label: 'Chống chỉ định', value: '4', code: 'contraindication' },
  { id: 5, label: 'Tương tác thuốc', value: '5', code: 'drugInteraction' },
  { id: 6, label: 'Bảo quản', value: '6', code: 'storage' },
  { id: 7, label: 'Quá liều', value: '7', code: 'drugOverdose' },
];

export const tabsProductDataV2 = [
  { id: 1, label: 'Thông tin chung', value: '1', code: 'description' },
  { id: 2, label: 'Thành phần', value: '2', code: 'ingredients' },
  { id: 3, label: 'Chỉ định', value: '3', code: 'indication' },
  { id: 4, label: 'Liều lượng - Cách dùng', value: '4', code: 'dosage' },
  { id: 5, label: 'Chống chỉ định', value: '5', code: 'contraindication' },
  { id: 6, label: 'Tương tác thuốc', value: '6', code: 'drugInteraction' },
  { id: 7, label: 'Bảo quản', value: '7', code: 'storage' },
  { id: 8, label: 'Quá liều', value: '8', code: 'drugOverdose' },
];

export const PAGE_SIZE_12 = 12;
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE = 20;
export const PAGE_SIZE_30 = 30;
export const PAGE_SIZE_28 = 28;
export const PAGE_SIZE_24 = 24;
export const ONE_DAY = 86400000;

export const MAX_PRODUCT_QTY_DISPLAY = 100000;

// search bar
export const ENTERPRISE = 'ENTERPRISE';
// export const SEARCH_TYPE = 'search-type';
export const THUOC_VA_HOAT_CHAT = 1;
export const HOAT_CHAT = 2;
export const NHA_BAN_HANG = 3;
export const THUOC = 4;
export const SEARCH_TYPE_LIST = [
  { label: 'Thuốc & Hoạt Chất', value: THUOC_VA_HOAT_CHAT },
  { label: 'Thuốc', value: THUOC },
  { label: 'Hoạt Chất', value: HOAT_CHAT },
  { label: 'Nhà Bán Hàng', value: NHA_BAN_HANG },
];
export const orderTabList = [
  { id: 1, label: 'Mã đơn', value: 'ORDER_ID' },
  { id: 2, label: 'Sản phẩm', value: 'PRODUCT' },
  { id: 3, label: 'Ngày tháng', value: 'TIME' },
];

export const getTrackingData = (url) => {
  const dataTracking = [
    {
      action: 'CLICK_GO_TO_PRODUCTS_PAGE',
      trackingPath: '/products',
    },

    {
      action: 'CLICK_GO_TO_INGREDIENTS_PAGE',
      trackingPath: '/ingredients',
    },

    {
      action: 'CLICK_GO_TO_QUICKORDER_PAGE',
      trackingPath: '/quick-order',
    },

    {
      action: 'CLICK_GO_TO_CAMPAIGN_PAGE',
      trackingPath: '/khuyenmai',
    },

    {
      action: 'CLICK_GO_TO_PROMO_CODES_PAGE',
      trackingPath: '/promo-codes',
    },

    {
      action: 'CLICK_GO_TO_SELLER_PAGE',
      trackingPath: '/sellers',
    },
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const data of dataTracking) {
    if (url.includes(data.trackingPath)) return data;
  }

  return {
    action: 'CLICK_GO_TO_EVENTS_PAGE',
    trackingPath: '/events',
  };
};

// SEO
export const DEFAULT_THUOCSI_SUBTITLE = 'Đặt thuốc sỉ rẻ hơn tại thuocsi.vn';
export const DEFAULT_THUOCSI_TITLE = 'thuocsi.vn';
export const DEFAULT_BUYMED_TITLE = 'buymed';
export const DEFAULT_THUOCSI_LONG_TITLE = 'Tra cứu và đặt thuốc giá sỉ nhanh tại thuocsi.vn';
export const DEFAULT_THUOCSI_DESCRIPTION = 'Sàn thương mại dược phẩm uy tín toàn quốc với 10000+ sản phẩm chính hãng';

// bulk-order
export const DEFAULT_BULK_ORDER_TITLE = 'Đặt hàng số lượng lớn – Đặt thuốc sỉ rẻ hơn tại thuocsi.vn';

export const MAP_BTN_TITLE_BY_REDIRECT_URL = {
  '/sanphammoi': 'Xem tất cả',
};

export const VISITED_URLS_STORAGE_KEY = 'visitedUrls';

export const DEFAULT_EXPANDED_STORAGE_KEY = 'defaultExpandedCategory';

export const SEARCH_RESULT_STORAGE_KEY = 'searchResult';

export const settingsProductHome = {
  className: 'section-outstanding__slider section-outstanding__slider__new',
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  rows: 1,
  arrows: true,
  autoplaySpeed: 5000,
  speed: 1500,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

export const MapPageSubstituteTracking = {
  products: 'product-list',
  'product/[slug]': 'product-detail-page',
  'quick-order': 'quick-order',
  cart: 'cart',
  substitutes: 'substitute-products-listing',
};
