import { getPathProductBySlug } from 'constants/Paths';

export const INSIDER_PAGE_TYPE = {
  CONFIRMATION: 'Confirmation',
  OTHER: 'Other',
};

export const INSIDER_CART_SOURCE = {
  REDUCER: 'REDUCER', // From src/context/Cart/CartReducer.js
  CART_STATE: 'CART_STATE', // From src/zustand-lib/useCart.ts
};

export const CUSTOMER_TYPE = {
  NEWBIE: "Newbie", // User is actived within 7 days, do not have any order
  NO_ORDER: "NoOrder", // User is actived > 7 days, do not have any order
  ONLY_1_ORDER: "Only1Order", // Have 1 order only
  ONLY_2_ORDER: "Only2Order", // Have 2 orders only
}

/**
 * @param {object} user
 * @param {object} user.account
 * @param {string} user.account.createdTime
 * @param {number} user.ordersCount
 * @param {boolean} user.isActive
 * @returns {string}
 */
const generateCustomerType = (user) => {
  if (!user || !user.isActive || !user?.account?.createdTime) return "";

  const createdTime = user?.account?.createdTime;
  const createdDate = new Date(createdTime);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - createdDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if (user.ordersCount === 2) return CUSTOMER_TYPE.ONLY_2_ORDER;
  if (user.ordersCount === 1) return CUSTOMER_TYPE.ONLY_1_ORDER;
  if (!user.ordersCount && diffDays > 7 ) return CUSTOMER_TYPE.NO_ORDER;
  return CUSTOMER_TYPE.NEWBIE;
}

const convertPhoneNumber = (phone) => {
  try {
    if (!phone) {
      return null;
    }
    if (phone.startsWith('+84')) return phone;
    if (phone.startsWith('84')) return `+${phone}`;
    if (phone.startsWith('0')) return `+84${phone.substring(1, phone.length)}`;
    return `+84${phone}`;
  } catch (error) {
    console.error('Error: ', error);
  }
  return null;
};

export function convertUser(user) {
  return {
    uuid: user?.customerID?.toString(),
    email: user?.email,
    phone_number: convertPhoneNumber(user?.phone || user?.phoneNumber),
    name: user?.name,
    gdpr_optin: true,
    email_optin: true,
    sms_optin: true,
    orders_count: user?.ordersCount || 0,
    is_activated: user?.isActive,
    custom: {
      loyalty_point: user?.point,
      thanh_pho: user?.thanh_pho || '',
    },
    customer_type: generateCustomerType(user),
  };
}

export function updateInsiderCartInfo(data, source) {
  if (window && window.insider_object && window.insider_object.user && data) {
    window.insider_object.user.cart_info = (() => {
      switch (source) {
        case INSIDER_CART_SOURCE.REDUCER:
          return convertCartItemsToInsiderObj(data);
        case INSIDER_CART_SOURCE.CART_STATE:
          return convertCartItemGroupsToInsiderObj(data);
        default:
          return [];
      }
    })();
    // console.log(source, 'data=', data);
    // console.log('window.insider_object.user.cart_info=');
    // console.table(window.insider_object.user.cart_info);
  }
}

function convertCartItemsToInsiderObj(cartItems) {
  if (!cartItems) return [];

  return cartItems.map((item) => ({
    name: item.name,
    product_name: item.name,
    product_id: item.productId,
    quantity: item.quantity,
    origin_price: Number(item.salePrice || item.price || item.deal?.price || 0) || 0,
    price: Number(item.deal?.price || item.price || item.salePrice || 0) || 0,
    product_url: getPathProductBySlug(item.slug),
    image_url: (item.imagesProxy && item.imagesProxy[0]) || '',
  }));
}

function convertCartItemGroupsToInsiderObj(cartItemGroups) {
  if (!cartItemGroups) return [];

  return cartItemGroups
    .map((group) =>
      group.products.map((item) => ({
        name: item.name,
        product_name: item.name,
        product_id: item.id,
        quantity: item.quantityInCart,
        origin_price: Number(item.noneVoucherPrice || item.price) || 0,
        price: Number(item.discountPrice || item.noneVoucherPrice || item.price) || 0,
        product_url: getPathProductBySlug(item.slug),
        image_url: item.imageUrl || '',
      })),
    )
    .flat();
}

export function initInsiderUser(user) {
  if (user && window && !window.insider_object) {
    const userInfo = convertUser(user);
    window.insider_object = {
      user: userInfo,
    };
  }
}

export function registerSuccess(user) {
  if (user && window) {
    const userInfo = convertUser(user);

    window.insider_object = {
      user: userInfo,
      email: user?.email || '',
      phone_number: convertPhoneNumber(user?.phone || user?.phoneNumber),
      page: {
        type: 'dangky_success',
      },
    };
  }
}

export function setPageType(type) {
  if (window)
    window.insider_object = {
      page: {
        type,
      },
    };
}

export default {
  registerSuccess,
  setPageType,
  updateInsiderCartInfo,
};
