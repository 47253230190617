import { DEFAULT_EXPANDED_STORAGE_KEY, SEARCH_RESULT_STORAGE_KEY, VISITED_URLS_STORAGE_KEY } from 'constants/data';
import { isEmpty } from 'utils/ObjectUtils';

const DEFAULT_TTL = 24 * 60 * 60 * 1000; // 1 day in milliseconds
const DEFAULT_VERSION = 1;

export function serializeFunc(data, fieldNames) {
  const object = {};
  fieldNames?.forEach((name, index) => {
    object[index] = data[name];
  });
  return isEmpty(object) ? null : object;
}

export function deserializeFunc(serializedData, fieldNames) {
  const object = {};
  fieldNames?.forEach((name, index) => {
    object[name] = serializedData[index];
  });
  return isEmpty(object) ? null : object;
}

// Utility function to get and set data with expiration in localStorage
export function getWithExpiry(key = '', { v = DEFAULT_VERSION } = {}) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry && item.v !== v) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export function setWithExpiry(key, value, { ttl = DEFAULT_TTL, v = DEFAULT_VERSION } = {}) {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
    v,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

const clearStorage = () => {
  if (window && window.localStorage) {
    const preservedPrefixes = ['ins', 'sp', 'step', 'time-rating', 'finish', 'lastOpenRemindDate', 'dropDownMenuList'];

    for (const key of Object.keys(localStorage)) {
      if (!preservedPrefixes.some((prefix) => key.startsWith(prefix))) {
        localStorage.removeItem(key);
      }
    }
    localStorage.setItem('logout', new Date());
    localStorage.setItem(VISITED_URLS_STORAGE_KEY, '[]');
    localStorage.removeItem(DEFAULT_EXPANDED_STORAGE_KEY);
    localStorage.removeItem(SEARCH_RESULT_STORAGE_KEY);
  }
};

export default {
  clearStorage,
};

