import { getData, getFirst, isValid } from 'clients/Clients';
import ProductClientV2 from 'clients/ProductClientV2';
import WebServiceClient, { getProductRecommendation } from 'clients/WebServiceClient';
import { ENUM_ORDER_STATUS, ENUM_ORDER_TYPE } from 'constants/Enums';
import { HTTP_STATUS } from 'constants/Enums/http';
import { PAGE_SIZE, PAGE_SIZE_12, PAGE_SIZE_30, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { PreventSearchKeywords, PreventSearchKeywordsAlias } from 'constants/data/search';
import { convertSlug, mapDataProduct } from 'services/ProductServiceV2';
import { changeAlias } from 'utils/StringUtils';
import WebServiceUtils from 'utils/WebServiceUtils';

const DEFAULT_SEARCH_STRATEGY = {
  text: true,
  keyword: true,
  ingredient: true,
};

// product
async function getDataProductDetail({ ctx, slug = '', isConvertProduct, productDetailPage = false }) {
  const params = {};

  if (slug) {
    params.q = convertSlug(slug);
  } else {
    const { query } = ctx;
    params.q = convertSlug(query?.slug);
  }
  const { params: newParams } = WebServiceUtils.addQueryOption({ params, documentFile: true, productDetailPage });
  const result = await WebServiceClient.loadProductDetail({
    ctx,
    params: { ...newParams, queryOption: newParams?.queryOption + 'getWishList,isGetSKUReplace' },
  });
  if (!isValid(result)) return result;
  return mapDataProduct({ ctx, result, isConvertProduct });
}

async function getDataProductBySlugs({ ctx, slugs = [] }) {
  const results = await Promise.all(
    slugs.map((slug) => {
      const params = {
        q: convertSlug(slug),
      };
      const { params: newParams } = WebServiceUtils.addQueryOption({ params });
      return WebServiceClient.loadProductDetail({ ctx, params: newParams });
    }),
  );
  const result = {
    status: HTTP_STATUS.Ok,
    data: results?.filter((res) => isValid(res))?.map((productRes) => getFirst(productRes)) || [],
  };

  return mapDataProduct({ ctx, result, isGetQuantity: false, isAvailable: true });
}

// search
async function getProductsFuzzySearch({ body, useMapProduct = false, isSkuReplaceProductList = false }) {
  const { body: bodyReq } = WebServiceUtils.addQueryOption({ body });
  const { text } = bodyReq || {};
  // chặn search những keyword cấm
  if (text) {
    const textSearch = text.toLocaleLowerCase().trim();
    const lengthTextSearch = textSearch?.split(' ').length;

    if (
      (lengthTextSearch === 1 && PreventSearchKeywords.indexOf(textSearch) >= 0) ||
      textSearch.toLocaleLowerCase().startsWith('http') ||
      PreventSearchKeywordsAlias.indexOf(changeAlias(textSearch)) >= 0
    ) {
      return { status: HTTP_STATUS.NotFound, msg: 'Keyword is not valid' };
    }
  }
  const result = await WebServiceClient.loadProductsFuzzySearch({
    body: {
      ...bodyReq,
      queryOption: {
        ...bodyReq?.queryOption,
        getShadowSKU: true,
        getWishList: true,
        isReplacePriceAfterVoucher: bodyReq.isReplacePriceAfterVoucher || false,
      },
    },
  });
  if (!isValid(result)) {
    return result;
  }
  if (useMapProduct) return mapDataProduct({ result, isProductCard: true, isSkuReplaceProductList });

  return getData(result);
}

async function searchByKeywords(keyword = '', hasIngredients = true) {
  const searchStrategy = {
    text: true,
    keyword: true,
    ingredient: hasIngredients,
  };
  const body = {
    text: keyword,
    offset: 0,
    limit: PAGE_SIZE_30,
    searchStrategy,
  };

  return getProductsFuzzySearch({ body });
}

// cart
async function getCart({ ctx }) {
  const cartRes = await WebServiceClient.loadCart({
    ctx,
    params: {
      queryOption: 'price,getPaymentMethod',
    },
  });

  if (isValid(cartRes)) {
    return getFirst(cartRes);
  }
  return cartRes;
}

// chuyển data mới thành cấu trúc cũ để ít thay đổi nhất
const convertProductLiteToProductNormal = (data) =>
  data?.map(({ displayPrice, name, slug, sellerInfo, productID }) => ({
    displayPrice,
    name,
    sku: {
      name,
      slug,
      sellerCode: sellerInfo?.sellerCode,
      retailPriceValue: displayPrice,
    },
    product: {
      name,
      productID,
    },
    slug,
    sellerInfo,
  }));

// search lite dành cho mỗi cái search ở NAVBAR
const searchFuzzyLite = async (body, getAllDataFromRes = false) => {
  const searchRes = await WebServiceClient.searchFuzzyLite(body);
  const data = convertProductLiteToProductNormal(getData(searchRes));
  if (getAllDataFromRes) {
    return {
      ...searchRes,
      data,
    };
  }
  return data;
};

const MINUTES_30 = 1800000;

export const getOrderDetail = async ({ ctx, orderId, isNewFlow = true }) => {
  const orderRes = await WebServiceClient.getOrderDetail({ ctx, params: { q: JSON.stringify({ orderId, isNewFlow }) } });

  if (!isValid(orderRes)) {
    return orderRes;
  }

  const order = getFirst(orderRes, {});

  const { status, createdTime, redeemCode = null, redeemApplyResult = null, totalDiscount = null, orderItems = [], invoiceInfo = {} } = order;

  if (redeemCode?.length > 0 && !redeemApplyResult) {
    const convertRedeem = [];
    if (totalDiscount > 0) {
      convertRedeem.push({
        code: redeemCode[0],
        discountValue: totalDiscount,
        canUse: true,
      });
    } else {
      const skuGift = orderItems?.find((item) => item?.type === 'GIFT')?.sku;
      // Thuy29Dec2022: cần convert vì ko migrate cho đơn hàng cũ
      if (skuGift) {
        convertRedeem.push({
          code: redeemCode[0],
          gifts: [{ sku: skuGift, quantity: 1 }],
          canUse: true,
        });
      }
    }

    order.redeemApplyResult = convertRedeem;
  }

  // logic check update
  // order can edit if status === wait to confirm && 30 minutes
  order.canEdit = status === ENUM_ORDER_STATUS.WAIT_TO_CONFIRM && +new Date() - +new Date(createdTime) <= MINUTES_30;
  // thêm invoices để tránh thay đổi code nhiều
  order.invoices = invoiceInfo?.invoices || [];

  // check can edit :  order have item combo or deal can't edit
  order.canEdit =
    order.canEdit &&
    orderItems.filter(
      (item) => item && (item.type === ENUM_ORDER_TYPE.DEAL || item.type === ENUM_ORDER_TYPE.COMBO || item.type === ENUM_ORDER_TYPE.CAMPAIGN),
    ).length === 0;

  orderRes.data = [order];
  return orderRes;
};

const loadDataProductWeb = async ({
  query,
  filter = {},
  isReplacePriceAfterVoucher = false,
  isSkuReplaceProductList = false,
  productListPage = false,
  getTotal = false,
  appSetting,
}) => {
  // check currentTab -> filter
  if (query.tag) {
    if (query.extraTag) {
      filter.tags = [query.extraTag, query.tag];
      delete filter.tag;
    } else {
      filter.tag = query.tag;
    }
    // }
  } else if (query.extraTag) {
    filter.tag = query.extraTag;
  }

  if (query.manufacturer) {
    filter.manufacturer = query.manufacturer;
  }
  if (query.category) {
    filter.category = query.category;
  }

  if (query.filter) {
    let categoryFilters = [];
    try {
      categoryFilters = JSON.parse(query.filter)?.categoryFilters || [];
    } catch (e) {
      categoryFilters = [];
    }

    const combinedCategoryFilters = categoryFilters.filter((item) => item.isCombined) || [];

    categoryFilters = categoryFilters.filter(
      (cat) =>
        cat.code !== cat.categoryCodes?.[0] ||
        cat.efficacyCodes?.length > 0 ||
        combinedCategoryFilters.every((item) => !item.categoryCodes?.includes(cat.code)),
    );

    // eslint-disable-next-line no-param-reassign
    filter = {
      ...filter,
      categoryFilters,
    };
  }

  // end
  const page = Number(query?.page - 1 || 0);
  const searchType = Number(query?.searchType || THUOC_VA_HOAT_CHAT);
  const body = {
    ...query,
    text: query?.q || query?.text || null,
    offset: page * PAGE_SIZE_12,
    limit: appSetting?.LIMIT_PRODUCT_LIST?.limit || PAGE_SIZE_12,
    getTotal,
    filter,
    sort: query?.sort || '',
    isAvailable: query?.isAvailable === 'true',
    searchStrategy: {
      text: true,
      keyword: true,
      ingredient: searchType === THUOC_VA_HOAT_CHAT,
    },
    isReplacePriceAfterVoucher,
  };

  return ProductClientV2.getDataProductList({
    body: {
      ...body,
      queryOption: {
        ...body?.queryOption,
        getShadowSKU: true,
        getWishList: true,
        isReplacePriceAfterVoucher: body.isReplacePriceAfterVoucher || false,
        isSkuReplaceProductList,
        isGetSKUReplace: true,
        productListPage,
      },
    },
  });
};

const loadDataProductMobileClient = async ({ page = 1, query, isEmptySearch = false }) => {
  const filter = {};
  const defaultQuerySearch = '';
  // check currentTab -> filter
  if (query.tag) {
    // if (query.tag === 'DOCQUYENGIATOT' && query.provinceCode !== undefined) {
    //   const formatQueryProvinceCode = `PROVINCE_${query.provinceCode}`;
    //   // eslint-disable-next-line no-param-reassign
    //   filter.tags = [query.tag, formatQueryProvinceCode];
    // } else {
    // eslint-disable-next-line no-param-reassign
    filter.tag = query.tag;
    // }
  }
  if (query.category) {
    filter.category = query.category;
  }
  if (query.manufacturer) {
    filter.manufacturer = query.manufacturer;
  }

  // end
  const sort = query?.sort || '';
  const searchPage = Number(page) || Number(query?.page) || 1;
  const searchType = Number(query?.searchType || THUOC_VA_HOAT_CHAT);
  const searchStrategy = {
    text: true,
    keyword: true,
    ingredient: searchType === THUOC_VA_HOAT_CHAT,
  };
  const searchText = isEmptySearch ? defaultQuerySearch : query?.q || query?.text;
  const body = {
    ...query,
    page: searchPage,
    text: searchText,
    offset: (searchPage - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
    getTotal: true,
    getPrice: true,
    filter,
    sort,
    isAvailable: query?.isAvailable === 'true',
    searchStrategy,
    // allSku: true,
  };
  return getProductsFuzzySearch({ body, useMapProduct: true });
};

export const verifyTaxCode = async ({ taxCode, customerName, customerAddress }) => {
  return WebServiceClient.verifyTaxCode({ taxCode, customerName, customerAddress });
};

export async function getPopup({ type, platform }) {
  return WebServiceClient.getPopup({
    params: {
      type,
      platform,
    },
  });
}

export async function getProductRecommendationData({ ctx, q = {}, offset = 0, limit = 20, ...options } = {}) {
  const result = await getProductRecommendation({
    ctx,
    body: {
      q,
      offset,
      limit,
      queryOption: {
        isReplacePriceAfterVoucher: true,
      },
      ...options,
    },
  });
  if (!isValid(result)) return result;

  return mapDataProduct({ result });
}

export default {
  getDataProductDetail,
  getDataProductBySlugs,
  getProductsFuzzySearch,
  searchByKeywords,
  getCart,
  searchFuzzyLite,
  loadDataProductWeb,
  loadDataProductMobileClient,
  getOrderDetail,
  verifyTaxCode,
};

