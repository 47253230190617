import { GET, getSessionToken, POST, PUT } from './Clients';

const ACCOUNT_API_PREFIX = '/core/account/v1';
const CUSTOMER_API_PREFIX = '/marketplace/customer/v1';

export async function getUserWithContext(ctx, retry = 0) {
  const ss = getSessionToken(ctx);
  if (!ss) {
    return { isAuthenticated: false };
  }
  return GET({
    ctx,
    url: `${CUSTOMER_API_PREFIX}/me`,
    retry,
  });
}

export async function login(body) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/authentication`,
    body,
    isAuth: false,
  });
}

export async function logout() {
  return PUT({ url: `${ACCOUNT_API_PREFIX}/logout` });
}

export async function passwordRecovery(body) {
  return POST({
    url: `${ACCOUNT_API_PREFIX}/password-recovery`,
    body,
    isBasic: true,
    isAuth: false,
  });
}

export async function passwordUpdate(body) {
  return PUT({
    url: `${ACCOUNT_API_PREFIX}/password-recovery`,
    body,
    isBasic: true,
    isAuth: false,
  });
}

// { username password }
export async function signUp(body) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/register`,
    body,
    isAuth: false,
  });
}

export async function registerGuest(body) {
  return POST({
    url: `${CUSTOMER_API_PREFIX}/guest`,
    body,
    isBasic: true,
  });
}

export async function registerV1({ t }) {
  return GET({
    url: `${CUSTOMER_API_PREFIX}/register`,
    params: { t },
    isAuth: false,
    isBasic: true,
  });
}

export async function getAccountInfo({ ctx }) {
  return GET({
    ctx,
    url: `${ACCOUNT_API_PREFIX}/me`,
  });
}

export async function getUser(retry = 0) {
  const result = await GET({
    url: `${CUSTOMER_API_PREFIX}/me`,
    params: { getCustomer: true },
    retry,
  });
  return result;
}

export async function newPassword(body) {
  return PUT({
    url: `${ACCOUNT_API_PREFIX}/me/password`,
    body,
    isAuth: true,
  });
}

export async function createPassword(body) {
  return POST({
    url: `${ACCOUNT_API_PREFIX}/account/create-password`,
    body,
    isAuth: true,
  });
}

export async function CreatePasswordOtp(body) {
  return PUT({
    url: `${ACCOUNT_API_PREFIX}/account/create-password`,
    body,
    isAuth: true,
  });
}

export async function getAccount(retry = 0) {
  const result = await GET({
    url: `${CUSTOMER_API_PREFIX}/me`,
    params: { getAccount: true, getFromMainDB: true },
    retry,
  });
  return result;
}

export default {
  login,
  getUser,
  signUp,
  registerGuest,
  getUserWithContext,
  getAccountInfo,
  passwordRecovery,
  passwordUpdate,
  registerV1,
  newPassword,
  logout,
  createPassword,
  CreatePasswordOtp,
  getAccount,
};
