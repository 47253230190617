import { CUSTOMER_API, CUSTOMER_API_PREFIX, FRONTEND_APIS_PREFIX } from 'constants/APIUriV2';
import { LOYALTY_STATUS } from 'constants/Enums/loyalty';
import {DELETE, GET, GET_ALL, POST, PUT, getData, isValid} from './Clients';
import {parseJSON} from "utils/jsonParse";
import {APIStatus} from "@thuocsi/customer-live-chat/src/utils/common";

const DEFAULT_TIME_EXPIRED = 900000

const retrySendSms = ({ code }) => {
  const url = CUSTOMER_API.RETRY_SEND_SMS;
  return POST({ url, params: { code } });
};

const sendSms = ({ phoneNumber }) => {
  const url = CUSTOMER_API.SEND_SMS;
  return POST({ url, body: { phone: phoneNumber } });
};

async function getOrder({ status }) {
  const url = CUSTOMER_API.ORDER + (status && status.length > 0 ? `?status=${status}` : '');
  return GET({ url, mock: true });
}

async function getReferral({ params }) {
  return GET({ url: CUSTOMER_API.REFERRAL, params });
}

export async function getWallet() {
  const result = await GET({ url: CUSTOMER_API.WALLET, mock: true });
  if (!isValid(result)) {
    return [];
  }
  return result;
}

export async function getPromo() {
  const result = await GET({ url: CUSTOMER_API.PROMO, mock: true });
  return getData(result);
}

export async function updateProfile(data) {
  const url = CUSTOMER_API.INFO;
  return PUT({ url, body: data });
}

export async function updateProfileEnterprise(data) {
  const url = CUSTOMER_API.UPDATE_INFO_ENTERPRISE;
  return PUT({ url, body: data });
}

export async function getBankAccount(ctx) {
  const url = CUSTOMER_API.BANK_ACCOUNT;
  const result = await GET({ url, ctx });
  return getData(result);
}

export async function loginY360Account(ctx) {
  const url = CUSTOMER_API.LOGIN_Y360_ACCOUNT;
  return POST({ url, body: {}, ctx });
}

export async function logoutY360Account(ctx) {
  const url = CUSTOMER_API.LOGOUT_Y360_ACCOUNT;
  return POST({ url, body: {}, ctx });
}

const getListAddress = async ({ ctx, params }) => GET({ url: CUSTOMER_API.ADDRESS_ACCOUNT, ctx, params });

const createAddress = async ({ ctx, body }) => POST({ url: CUSTOMER_API.ADDRESS_ACCOUNT, body, ctx });

const deleteAddress = async ({ ctx, code, customerID }) => DELETE({ url: CUSTOMER_API.ADDRESS_ACCOUNT, ctx, params: { code, customerID } });

const updateAddress = async ({ ctx, body }) => PUT({ url: CUSTOMER_API.ADDRESS_ACCOUNT, ctx, body });

const updateAddressDefault = async ({ ctx, code, customerID }) =>
  PUT({ url: CUSTOMER_API.ADDRESS_ACCOUNT, ctx, body: { code, isDefault: true, customerID } });

const getListLoyalty = async (ctx) => GET({ url: CUSTOMER_API.LOYALTY_LIST, ctx, params: { status: LOYALTY_STATUS.ACTIVE } });

const getListHistoryLoyalty = async ({ ctx, offset, limit, q, type = null, getIncRankPointOnly = false }) =>
  GET({ url: CUSTOMER_API.HISTORY_LOYALTY_LIST, ctx, params: { offset, limit, getTotal: true, q, type, getIncRankPointOnly } });
const getAllListHistoryLoyalty = async ({ ctx, q, type = null }) => GET_ALL({ url: CUSTOMER_API.HISTORY_LOYALTY_LIST, ctx, params: { q, type } });

const exchangeLoyalty = async ({ ctx, body }) => POST({ url: CUSTOMER_API.LOYALTY, ctx, body });

const uploadFile = async ({ ctx, body }) => POST({ url: CUSTOMER_API.UPLOAD_DOCUMENT, ctx, body });

const getListInvoiceInfo = async ({ ctx }) => GET({ url: CUSTOMER_API.INVOICE_LIST, ctx });

const createInvoiceInfo = async ({ ctx, body }) => POST({ url: CUSTOMER_API.INVOICE_INFO, body, ctx });

const deleteInvoiceInfo = async ({ ctx, code, customerID }) => DELETE({ url: CUSTOMER_API.INVOICE_INFO, ctx, params: { code, customerID } });

const updateInvoiceInfo = async (body) => {
  const url = CUSTOMER_API.INVOICE_INFO;
  return PUT({ url, body });
};

const updateInvoiceInfoOrder = async (body) => {
  const url = '/marketplace/order/v2/order/invoice';
  return PUT({ url, body });
};

const getBankAccountInfo = async ({ ctx }) => GET({ url: CUSTOMER_API.BANK_ACCOUNT_LIST, ctx });

const createBankAccountInfo = async ({ ctx, body }) => POST({ url: CUSTOMER_API.BANK_ACCOUNT_INFO, body, ctx });

const updateBankAccountInfo = async ({ body }) => PUT({ url: CUSTOMER_API.BANK_ACCOUNT_INFO, body });

const getLevelList = async ({ ctx }) => GET({ url: CUSTOMER_API.LEVEL_LIST, ctx });

// update api updateAccountInfo apm - 173 - https://buymed.atlassian.net/browse/APM-173

const updateCustomerInfo = async (dataAccount) => PUT({ url: CUSTOMER_API.UPDATE_INFO_ACCOUNT, body: dataAccount });

// const updateInfoEnterprise = async (dataEnterprise) => PUT({ url: CUSTOMER_API.UPDATE_INFO_ENTERPRISE, body: dataEnterprise });

const updateCaculatePoints = async () => GET({ url: CUSTOMER_API.CACULATE_POINTS_CUSTOMER });

const priorityCustomer = async ({ ctx, body }) => POST({ url: CUSTOMER_API.PRIORITY_CUSTOMER, ctx, body });
export const asyncCustomerLicenseImageInfo = async ({ body }) => POST({ url: '/integration/gcv/v1/customer-license/convert/async', body });
export const asyncCustomerLicenseImageInfoV2 = async ({ body }) => POST({ url: '/integration/gcv/v1/customer-license/convert-v2/async', body });

export const pingCustomerLicenseInfoCheck = async ({ params }) => GET({ url: '/integration/gcv/v1/customer-license/convert/check', params });

export const getRequsetLicenseTicket = async ({ params }) => GET({ url: CUSTOMER_API.REQUEST_LICENSE, params });

export const createRequestLicenseTicket = async ({ body }) => POST({ url: CUSTOMER_API.REQUEST_LICENSE, body });

export const updateRequestLicenseTicketStatus = async ({ body }) => PUT({ url: `${CUSTOMER_API.REQUEST_LICENSE}/switch-status`, body });

export const getUserResetLoyaltyPoint = async ({ ctx }) =>
  GET({
    url: CUSTOMER_API.RESET_LOYALTY_POINT,
    ctx,
  });

/**
 *
 * @param {object} param0
 * @param {import('next').GetServerSidePropsContext=} param0.ctx
 * @param {{feature: string|string[]}} param0.q
 * @returns
 */
export async function getUserLogGuide({ ctx, q }) {
	const featureKey = q.feature
	const localCache = localStorage.getItem(featureKey)

	const qCache = parseJSON(localCache, null)
	if(qCache) {
		const now = new Date().getTime()
		if(now <= qCache.expired) {
			return qCache.value
		}
	}

	const res = await GET({
		url: `${FRONTEND_APIS_PREFIX}/me/guide`,
		ctx,
		params: {
			q: q ? JSON.stringify(q) : undefined,
		},
	});

	if(res.status === APIStatus.OK) {
		const cache = {
			value: res,
			expired: new Date().getTime() + DEFAULT_TIME_EXPIRED
		}

		localStorage.setItem(q.feature, JSON.stringify(cache))
	}
	return res
}

/**
 *
 * @param {object} param0
 * @param {string} param0.feature
 * @param {string=} param0.featureName
 * @param {boolean=} param0.isViewAll - true = user have clicked through all the guide
 * @returns
 */
export async function postUserLogGuide({ feature, featureName, isViewAll }) {
	const localCache = localStorage.getItem(feature)

	const cache = parseJSON(localCache, null)

	try {
		if(cache.value && cache.value?.data?.length) {
			if(typeof cache.value?.data[0] === 'object') {
				cache.value.data[0].isViewAll = isViewAll
				cache.value.data[0].isGuided = true
				localStorage.setItem(feature, JSON.stringify(cache))
			}
		}
	} catch(err) {
		localStorage.removeItem(feature)
	}

	return POST({
    url: `${CUSTOMER_API_PREFIX}/me/guide`,
    body: {
      feature,
      featureName,
      isViewAll,
    },
  });
}

export default {
  getOrder,
  getReferral,
  getWallet,
  getPromo,
  updateProfile,
  sendSms,
  retrySendSms,
  getBankAccount,
  getListAddress,
  createAddress,
  deleteAddress,
  updateAddressDefault,
  updateAddress,
  getListLoyalty,
  getListHistoryLoyalty,
  getAllListHistoryLoyalty,
  exchangeLoyalty,
  uploadFile,
  getListInvoiceInfo,
  getBankAccountInfo,
  createInvoiceInfo,
  deleteInvoiceInfo,
  updateInvoiceInfo,
  updateInvoiceInfoOrder,
  getLevelList,
  createBankAccountInfo,
  updateBankAccountInfo,
  updateCustomerInfo,
  updateProfileEnterprise,
  updateCaculatePoints,
  priorityCustomer,
  createRequestLicenseTicket,
  pingCustomerLicenseInfoCheck,
  asyncCustomerLicenseImageInfo,
  getUserResetLoyaltyPoint,
};
